<template>
  <el-row class="table">
    <span class="title">试卷批阅</span>
    <el-row class="row-bg">
      <el-input
        v-model.trim="studentName"
        placeholder="请输入学员姓名"
        @keyup.enter.native="search"
        class="tb-sm-input"
      ></el-input>
      <el-input
        v-model.trim="studentNo"
        placeholder="请输入学号"
        @keyup.enter.native="search"
        class="tb-sm-input"
      ></el-input>
      <el-button type="primary" size="medium" @click="search" class="tb-button">搜 索</el-button>
      <el-button type="primary" size="medium" @click="reset" class="tb-button">重 置</el-button>
      <el-button @click="refresh()" type="info" class="tb-button">刷新列表</el-button>
    </el-row>
    <el-table
      :data="dataList"
      border
      fit
      highlight-current-row
      class="tb-list mt-20"
    >
      <el-table-column label="试卷名" align="center" prop="exam.title"></el-table-column>
      <el-table-column label="课程" align="center" prop="exam.courseTitle"></el-table-column>
      <el-table-column label="学员" align="center">
        <template slot-scope="scope">
          <span v-if="Boolean(scope.row.user)">
            <span>[{{scope.row.user.loginName}}] {{scope.row.user.name}}</span>
          </span>
          <span v-else></span>
        </template>
      </el-table-column>
      <el-table-column label="提交时间" align="center">
        <template slot-scope="scope">
          <span>{{scope.row.finishTime | dateFormat}}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center" width="150">
        <template slot-scope="scope">
          <el-button type="primary" size="small" @click="readOver(scope.row)" class="tb-button">批 阅</el-button>
        </template>
      </el-table-column>
    </el-table>

    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="pagination.currentPage"
      :page-sizes="pagination.pageSizes"
      :page-size="pagination.pageSize"
      :layout="pagination.layout"
      :total="pagination.total"
    ></el-pagination>
  </el-row>
</template>

<script>
import { getListUserExamByTeacher } from '@/api/senate/paper'

export default {
  data () {
    return {
      studentName: '',
      studentNo: '',
      dataList: [],
      pagination: {
        currentPage: 1,
        pageSize: 10,
        pageSizes: [10, 20, 30, 40, 50, 60, 70, 80, 90, 100],
        layout: 'total, sizes, prev, pager, next, jumper',
        total: 0
      }
    }
  },
  created () {
    this.getListUserExamByTeacher()
  },
  methods: {
    search () {
      this.pagination.currentPage = 1
      this.getListUserExamByTeacher()
    },

    reset () {
      this.studentName = ''
      this.studentNo = ''
    },

    refresh () {
      this.pagination.currentPage = 1
      this.getListUserExamByTeacher()
    },

    async getListUserExamByTeacher () {
      const res = await getListUserExamByTeacher({
        pageNum: this.pagination.currentPage,
        pageSize: this.pagination.pageSize,
        studentName: this.studentName,
        studentNo: this.studentNo,
        isReview: false
      })
      this.dataList = res.body.list
      this.pagination.total = res.body.total
    },

    handleCurrentChange (val) {
      this.pagination.currentPage = val
      this.getListUserExamByTeacher()
    },
    handleSizeChange (val) {
      this.pagination.pageSize = val
      this.getListUserExamByTeacher()
    },
    readOver (row) {
      let routeUrl = this.$router.resolve({
        path: '/senate/paper-result',
        query: {
          id: row.id,
          // id: row.examId,
          userId: row.userId
        }
      })
      window.open(routeUrl.href, '_blank')
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../style/table.scss";
</style>
