var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-row",
    { staticClass: "table" },
    [
      _c("span", { staticClass: "title" }, [_vm._v("试卷批阅")]),
      _c(
        "el-row",
        { staticClass: "row-bg" },
        [
          _c("el-input", {
            staticClass: "tb-sm-input",
            attrs: { placeholder: "请输入学员姓名" },
            nativeOn: {
              keyup: function ($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                )
                  return null
                return _vm.search.apply(null, arguments)
              },
            },
            model: {
              value: _vm.studentName,
              callback: function ($$v) {
                _vm.studentName = typeof $$v === "string" ? $$v.trim() : $$v
              },
              expression: "studentName",
            },
          }),
          _c("el-input", {
            staticClass: "tb-sm-input",
            attrs: { placeholder: "请输入学号" },
            nativeOn: {
              keyup: function ($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                )
                  return null
                return _vm.search.apply(null, arguments)
              },
            },
            model: {
              value: _vm.studentNo,
              callback: function ($$v) {
                _vm.studentNo = typeof $$v === "string" ? $$v.trim() : $$v
              },
              expression: "studentNo",
            },
          }),
          _c(
            "el-button",
            {
              staticClass: "tb-button",
              attrs: { type: "primary", size: "medium" },
              on: { click: _vm.search },
            },
            [_vm._v("搜 索")]
          ),
          _c(
            "el-button",
            {
              staticClass: "tb-button",
              attrs: { type: "primary", size: "medium" },
              on: { click: _vm.reset },
            },
            [_vm._v("重 置")]
          ),
          _c(
            "el-button",
            {
              staticClass: "tb-button",
              attrs: { type: "info" },
              on: {
                click: function ($event) {
                  return _vm.refresh()
                },
              },
            },
            [_vm._v("刷新列表")]
          ),
        ],
        1
      ),
      _c(
        "el-table",
        {
          staticClass: "tb-list mt-20",
          attrs: {
            data: _vm.dataList,
            border: "",
            fit: "",
            "highlight-current-row": "",
          },
        },
        [
          _c("el-table-column", {
            attrs: { label: "试卷名", align: "center", prop: "exam.title" },
          }),
          _c("el-table-column", {
            attrs: { label: "课程", align: "center", prop: "exam.courseTitle" },
          }),
          _c("el-table-column", {
            attrs: { label: "学员", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    Boolean(scope.row.user)
                      ? _c("span", [
                          _c("span", [
                            _vm._v(
                              "[" +
                                _vm._s(scope.row.user.loginName) +
                                "] " +
                                _vm._s(scope.row.user.name)
                            ),
                          ]),
                        ])
                      : _c("span"),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "提交时间", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", [
                      _vm._v(
                        _vm._s(_vm._f("dateFormat")(scope.row.finishTime))
                      ),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "操作", align: "center", width: "150" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        staticClass: "tb-button",
                        attrs: { type: "primary", size: "small" },
                        on: {
                          click: function ($event) {
                            return _vm.readOver(scope.row)
                          },
                        },
                      },
                      [_vm._v("批 阅")]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c("el-pagination", {
        attrs: {
          "current-page": _vm.pagination.currentPage,
          "page-sizes": _vm.pagination.pageSizes,
          "page-size": _vm.pagination.pageSize,
          layout: _vm.pagination.layout,
          total: _vm.pagination.total,
        },
        on: {
          "size-change": _vm.handleSizeChange,
          "current-change": _vm.handleCurrentChange,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }